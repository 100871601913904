// src/utils/searchUtils.js

export function clickSearchBox(inputBox) {
    const dropDownDiv = document.getElementById('typeaheadDropdown')
    const resultBoxParent = document.querySelector('.result-box-parent');

    const handleClick = () => {

        resultBoxParent.appendChild(dropDownDiv);

        if (inputBox.value === "") {
            
    
        }

        // Show the dropdown if it was hidden
        dropDownDiv.style.display = 'block';
    };

    // Add event listener to input box
    const addListeners = () => {
        inputBox.addEventListener('click', handleClick);
    };

    // Remove event listener from input box
    const removeListeners = () => {
        inputBox.removeEventListener('click', handleClick);
    };

    addListeners();

    // Return a cleanup function
    return () => removeListeners();
}

export function closeResultBox() {
    const resultBox = document.getElementById('typeaheadDropdown');
    if (resultBox) {
        resultBox.style.display = 'none'; // Hide the dropdown instead of removing it
    }
}