import React, { useState, useEffect } from 'react';

const FullBetSlip = ({ selectedWager, containerEmpty, isThereASelectedElement, onClose  }) => {
    const [buttonMessage, setButtonMessage] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const [hasAnimated, setHasAnimated] = useState(false);
    const animationStatus = containerEmpty === true ? 'single-card' : 'single-card-exist';
    const animationStatusSecond = containerEmpty === true ? 'betslip-section-heading' : 'betslip-section-heading-exist';

    const token = localStorage.getItem('token');
    const isAuthenticated = !!token; // True if token exists, false otherwise

    // Set button message based on authentication status
    const defaultButtonMessage = isAuthenticated
        ? `Place bet of ${selectedWager.wagerAmount.toLocaleString()} to win ${(parseInt(selectedWager.wagerAmount) * 2).toLocaleString()}`
        : 'Log in to Place Bets';

    // Initial button text
    React.useEffect(() => {
        setButtonMessage(defaultButtonMessage);
    }, [selectedWager, isAuthenticated]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setHasAnimated(true);
        }, 50);

        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

    const handleButtonClick = () => {
        setButtonMessage('');
        setIsSearching(true);

        // Simulate searching for 15 seconds
        setTimeout(() => {
            setButtonMessage('Unable to find opponent');
            setIsSearching(false);
        }, 15000);
    };

    

    return (
        <div style={{ overflow: 'hidden', transition: 'height 0.3s ease-in 0s' }}>
            <div style={{ opacity: 1 }}>
                <div className="chessbook-content-inner-wrapper full">
                    <div className="chessbook-outcome scrollbar">
                        <div className="top-ref"></div>
                        <div className={`${animationStatusSecond} ${hasAnimated ? 'fade-in' : ''}`}>
                            <span>Randoms</span>
                        </div>
                        <div className={`${animationStatus} ${hasAnimated ? 'expand-in' : ''}`}>
                            <div className="card-animation-wrapper">
                                <div style={{ overflow: 'hidden', height: 'auto', transition: 'height 0.3s ease-in 0s' }}>
                                    <div style={{ transform: 'translateY(0%)' }}>
                                        <div className="hth-betslip-card-container">
                                            <div className="hth-betslip-card-wrapper">
                                                <div className="hth-betslip-xbutton-container">
                                                    <div className="xbutton-div" onClick={onClose}>
                                                        <svg
                                                            role="img"
                                                            aria-label="Close"
                                                            className="sportsbook__icon--ex"
                                                            fill="#ababab"
                                                            width="12"
                                                            height="12"
                                                            viewBox="0 0 32 32"
                                                        >
                                                            <title>Icon representing an X shape</title>
                                                            <path d="M18.848 16.384l10.848-10.848c0.352-0.352 0.352-0.96 0-1.312l-1.44-1.44c-0.352-0.352-0.96-0.352-1.312 0l-10.88 10.848h-0.032l-10.816-10.848c-0.352-0.352-0.96-0.352-1.312 0l-1.44 1.44c-0.352 0.352-0.352 0.96 0 1.312l10.848 10.848v0.032l-10.624 10.656c-0.352 0.352-0.352 0.96 0 1.312l1.44 1.44c0.352 0.352 0.96 0.352 1.312 0l10.656-10.656h0.032l10.656 10.656c0.352 0.352 0.96 0.352 1.312 0l1.44-1.44c0.352-0.352 0.352-0.96 0-1.312l-10.688-10.688c-0.032 0.032-0.032 0.032 0 0z"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="single-card-combination">
                                                    <div className="single-card-header">
                                                        <div className="single-card-game">
                                                            <span className="single-card-game-text">Chess</span>
                                                        </div>
                                                        <div className="single-card-comp">Head-to-Head</div>
                                                        <div className="single-card-time">{selectedWager.time}</div>
                                                    </div>
                                                    <div className="single-card-wager-amount">
                                                        <span className="single-card-wager-amount-text">{selectedWager.wagerAmount.toLocaleString()}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={handleButtonClick} disabled={isSearching} className="place-bet-button-container">
                <div className={`place-bet-button-wrapper ${isSearching ? 'button-searching' : 'loaded'}`}>
                    <div className={`place-bet-primary-text ${isSearching ? 'chessboard-animation' : ''}`}>
                        {buttonMessage}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FullBetSlip;