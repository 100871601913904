import React from 'react';
import RegistrationHeader from './RegistrationHeader';
import SmallerLogo from './SmallerLogo';
import Intro from './RegistrationIntro';
import RegistrationForm from './RegistrationForm';
import '../css/registrationPage.css';

const RegistrationLayout = ({ children }) => {
  return (
    <div className="registration-layout">
      <RegistrationHeader />
      <div className="flex min-h-full">
        <div className="register card samri-vepx8a">
          <SmallerLogo />
          <div className="content samri-1kvrppx top-spacing" style={{ marginLeft: '1rem' }}>
            <div className="modal-content samri-vepx8a">
              <Intro />
              <RegistrationForm />
            </div>
          </div>
        </div>

        <div className="hidden lg:mx-auto lg:mt-16 lg:flex lg:max-w-2xl xl:mt-24 xl:ml-10 xl:mr-0 xl:max-w-none xl:flex-none 2xl:ml-32">
          <div className="flex-none sm:max-w-5xl lg:max-w-none my-auto">
            <img 
              src="my-app/src/assets/images/screenshot.png" 
              alt="App screenshot" 
              width="2432" 
              height="1442" 
              className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 my-auto"
            />
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default RegistrationLayout;