import React from 'react';
import '../css/buttons.css';

const BlitzSection = ({ onWagerSelect }) => {
  const handleButtonClick = (event, wagerAmount, time) => {
    // Get all elements with the role="button" attribute
    const buttons = document.querySelectorAll('[role="button"]');
    
    // Remove 'chosen' class from all buttons
    buttons.forEach(button => button.classList.remove('chosen'));

    // Add 'chosen' class to the clicked button
    event.currentTarget.classList.add('chosen');

    onWagerSelect({ wagerAmount, time });
  };

  return (
    <section id="Blitz" className="tab">
      <ul>
        <li id="li">
          <div className="game-title">
            <div className="flex">
              <span>Blitz</span>
              <span className="icon blitz">
                <svg fill="#F9B234" height="16px" width="16px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#F9B234">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>lightning-bolt</title>
                    <path d="M23.5 13.187h-7.5v-12.187l-7.5 17.813h7.5v12.187l7.5-17.813z"></path>
                  </g>
                </svg>
              </span>
            </div>
            <a className='flex fg-0 ai-c gap-2' href="#">
              <span>View Tournaments</span>
              <svg width="5" height="9" viewBox="0 0 5 9" fill="rgb(172 255 195)" style={{ flexShrink: 0 }}>
                <g id="arrow-right_svg__Page-1" stroke="none" strokeWidth="1">
                  <path d="M.148.148a.5.5 0 000 .71l3.636 3.637L.148 8.143a.502.502 0 00.709.71l3.995-3.998a.5.5 0 000-.71L.857.149a.5.5 0 00-.71 0z" id="arrow-right_svg__arrow-right"></path>
                </g>
              </svg>
            </a>
          </div>
          <div id="3MinBZ" className="gamebox">
            <div className="time-parent">
              <div id="time-container">
                <span role="text" className="gamebox-time">3 min</span>
              </div>
            </div>
            <div className="rectangle"></div>
            <div className="button-section">
              <div className="row-1">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 10000, '3 min')}
                >
                  <span className="wager-text">+10,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 50000, '3 min')}
                >
                  <span className="wager-text">+50,000</span>
                </div>
              </div>
              <div className="row-2">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 100000, '3 min')}
                >
                  <span className="wager-text">+100,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 200000, '3 min')}
                >
                  <span className="wager-text">+200,000</span>
                </div>
              </div>
            </div>
            <div className="multiplier-payout">
              <div className="mp-child">
                <div className="multiplier"><span>2x</span></div>
                <div className="payout"><span>payout</span></div>
              </div>
            </div>
          </div>
        </li>
        <li id="li">
          <div id="32BZ" className="gamebox">
            <div className="time-parent">
              <div id="time-container">
                <span role="text" className="gamebox-time">3 | 2</span>
              </div>
            </div>
            <div className="rectangle"></div>
            <div className="button-section">
              <div className="row-1">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 10000, '3 | 2')}
                >
                  <span className="wager-text">+10,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 50000, '3 | 2')}
                >
                  <span className="wager-text">+50,000</span>
                </div>
              </div>
              <div className="row-2">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 100000, '3 | 2')}
                >
                  <span className="wager-text">+100,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 200000, '3 | 2')}
                >
                  <span className="wager-text">+200,000</span>
                </div>
              </div>
            </div>
            <div className="multiplier-payout">
              <div className="mp-child">
                <div className="multiplier"><span>2x</span></div>
                <div className="payout"><span>payout</span></div>
              </div>
            </div>
          </div>
        </li>
        <li id="li">
          <div id="5MinBZ" className="gamebox">
            <div className="time-parent">
              <div id="time-container">
                <span role="text" className="gamebox-time">5 min</span>
              </div>
            </div>
            <div className="rectangle"></div>
            <div className="button-section">
              <div className="row-1">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 10000, '5 min')}
                >
                  <span className="wager-text">+10,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 50000, '5 min')}
                >
                  <span className="wager-text">+50,000</span>
                </div>
              </div>
              <div className="row-2">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 100000, '5 min')}
                >
                  <span className="wager-text">+100,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 200000, '5 min')}
                >
                  <span className="wager-text">+200,000</span>
                </div>
              </div>
            </div>
            <div className="multiplier-payout">
              <div className="mp-child">
                <div className="multiplier"><span>2x</span></div>
                <div className="payout"><span>payout</span></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default BlitzSection;