import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Intro = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div>
      <div className="left-wrapper samri-kkwxsm">
        <h1 className="weight-semibold line-height-default align-left size-md text-size-md variant-highlighted with-icon-space samri-1myjzud">
          {isLoginPage ? 'Log In' : 'Create an Account'}
        </h1>
      </div>
      <div className="footer samri-kkwxsm">
        <span className="weight-normal line-height-default inline align-center size-default text-size-default variant-subtle with-icon-space samri-1myjzud">
          {isLoginPage ? (
            <>Don’t have an account?{' '}
              <Link to="/register">
                <button className="button variant-link size-sm align-left samri-1epmied">
                  <span className="content-or-loader samri-1uofbko">Sign Up</span>
                </button>
              </Link>
            </>
          ) : (
            <>Already have an account?{' '}
              <Link to="/login">
                <button className="button variant-link size-sm align-left samri-1epmied">
                  <span className="content-or-loader samri-1uofbko">Sign In</span>
                </button>
              </Link>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default Intro;