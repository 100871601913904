import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MainLayout from './components/HomePage';
import TournamentLayout from './components/TournamentLayout';
import RegistrationLayout from './components/RegistrationLayout';
import LoginLayout from './components/LoginPage';
import GameLayout from './components/GameRoomLayout';
import LobbyComponent from './components/Lobby'; 
import { useAxiosInterceptor } from './utils/axiosInterceptor';

function App() {
  useAxiosInterceptor();

  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="/register" element={<RegistrationLayout />} />
          <Route path="/login" element={<LoginLayout />} />
          <Route path="/tournament" element={<TournamentLayout />} />
          <Route path="/game/:roomId" element={
            <DndProvider backend={HTML5Backend}>
              <GameLayout />
            </DndProvider>
          } />
          <Route path="/lobby/:roomId" element={<LobbyComponent />} />
        </Routes>
    </div>
  );
}

export default App;