import React from 'react';

const TvPlayer = () => {
  return (
    <div className="tv-margin">
      <div className="m-1">
        <div className="tv-player-component">
          <iframe
            allow="autoplay; fullscreen"
            className="tv-iframe"
            src="//player.twitch.tv/?channel=emilyplayschess&autoplay=true&muted=false&parent=localhost"
            frameBorder="0"
            height="258"
            width="375"
            title="Twitch Stream"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default TvPlayer;