import React, { createContext, useContext, useState, useEffect } from 'react';
import { gql, useApolloClient, ApolloLink, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Define the GraphQL query to fetch user data
const GET_ME = gql`
  query GetMe {
    me {
      id
      email
      username
      profilePic
      balance
    }
  }
`;

// Create a User Context
const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const client = useApolloClient();

  useEffect(() => {
    const fetchUser = async () => {
      // Get the token from localStorage
      const token = localStorage.getItem('token');
      
      // Create an auth link with the token
      const authLink = setContext(() => ({
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      }));

      const authClient = new ApolloClient({
        link: ApolloLink.from([authLink, client.link]),
        cache: client.cache,
      });

      const { data } = await authClient.query({
        query: GET_ME,
        fetchPolicy: 'network-only', // Ensure it fetches from the server
      });

      setUser(data.me);
    };

    fetchUser();
  }, [client]);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};