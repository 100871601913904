import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from '../mutations';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ onShowSignUp }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [login, { loading, error: mutationError }] = useMutation(LOGIN_MUTATION);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError('');

    try {
      const { data } = await login({ variables: { email, password } });
    
      if (data?.login) {
        localStorage.setItem('token', data.login);
        navigate('/');
      } else {
        setError('Login failed. Please check your credentials.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Error logging in, please try again later.');
    }
  };

  return (
    <form
      id="loginForm"
      onSubmit={handleSubmit}
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      style={{ minWidth: 380 }}
    >
      <EmailInput email={email} setEmail={setEmail} error={error} />
      <PasswordInput password={password} setPassword={setPassword} error={error} />

      {error && <p className="error-message">{error}</p>} {/* Display error message if any */}

      <div className="gh-17w8">
        <button id="login-submit" role="button" type="submit" className="gh-w4pc" disabled={loading}>
          <div className="gh-141e">Log In</div>
        </button>
      </div>
    </form>
  );
};

export default LoginForm;