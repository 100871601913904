import React from 'react';
import '../css/buttons.css';

const BulletSection = ({ onWagerSelect }) => {
  const handleButtonClick = (event, wagerAmount, time) => {
    // Get all elements with the role="button" attribute
    const buttons = document.querySelectorAll('[role="button"]');
    
    // Remove 'chosen' class from all buttons
    buttons.forEach(button => button.classList.remove('chosen'));

    // Add 'chosen' class to the clicked button
    event.currentTarget.classList.add('chosen');

    onWagerSelect({ wagerAmount, time });
  };

  return (
    <section id="bullet" className="tab">
      <ul>
        <li>
          <div className="game-title">
            <div className="flex">
              <span>Bullet</span>
              <span className="icon bullet">
                <svg height="15px" width="15px" version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve" fill="#000000" stroke="#000000">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <style type="text/css">{`
                      .st0 { fill: #D2122E; }
                    `}</style>
                    <g>
                      <path className="st0" d="M509.915,2.067C498.446-9.417,371.29,29.161,331.086,60.974L451.04,180.927C482.853,140.708,521.415,13.567,509.915,2.067z"></path>
                      <path className="st0" d="M268.743,122.677l-41.344,10.328L42.211,318.208l12.047,12.063l-16.359,16.344l-12.063-12.047L0.008,360.396l151.594,151.608l25.844-25.844l-12.063-12.063l16.359-16.359l12.063,12.047l185.188-185.171l10.344-41.359c0,0,18.703-18.719,48.25-48.234L316.993,74.442C287.461,103.942,268.743,122.677,268.743,122.677z"></path>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <a className='flex fg-0 ai-c gap-2' href="tournamentBullet.html">
              <span>View Tournaments</span>
              <svg width="5" height="9" viewBox="0 0 5 9" fill="rgb(172 255 195)" style={{ flexShrink: 0 }}>
                <g id="arrow-right_svg__Page-1" stroke="none" strokeWidth="1">
                  <path d="M.148.148a.5.5 0 000 .71l3.636 3.637L.148 8.143a.502.502 0 00.709.71l3.995-3.998a.5.5 0 000-.71L.857.149a.5.5 0 00-.71 0z" id="arrow-right_svg__arrow-right"></path>
                </g>
              </svg>
            </a>
          </div>
        </li>
        <li id="li">
          <div id="1MinBLT" className="gamebox">
            <div className="time-parent">
              <div id="time-container">
                <span role="text" className="gamebox-time">1 min</span>
              </div>
            </div>
            <div className="rectangle"></div>
            <div className="button-section">
              <div className="row-1">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 10000, '1 min')}
                >
                  <span className="wager-text">+10,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 50000, '1 min')}
                >
                  <span className="wager-text">+50,000</span>
                </div>
              </div>
              <div className="row-2">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 100000, '1 min')}
                >
                  <span className="wager-text">+100,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 200000, '1 min')}
                >
                  <span className="wager-text">+200,000</span>
                </div>
              </div>
            </div>
            <div className="multiplier-payout">
              <div className="mp-child">
                <div className="multiplier">2x</div>
                <div className="payout">
                  <span>payout</span>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li id="li">
          <div id="21BLT" className="gamebox">
            <div className="time-parent">
              <div id="time-container">
                <span role="text" className="gamebox-time">2 | 1</span>
              </div>
            </div>
            <div className="rectangle"></div>
            <div className="button-section">
              <div className="row-1">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 10000, '2 | 1')}
                >
                  <span className="wager-text">+10,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 50000, '2 | 1')}
                >
                  <span className="wager-text">+50,000</span>
                </div>
              </div>
              <div className="row-2">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 100000, '2 | 1')}
                >
                  <span className="wager-text">+100,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 200000, '2 | 1')}
                >
                  <span className="wager-text">+200,000</span>
                </div>
              </div>
            </div>
            <div className="multiplier-payout">
              <div className="mp-child">
                <div className="multiplier">2x</div>
                <div className="payout">
                  <span>payout</span>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default BulletSection;