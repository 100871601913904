import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const newSocket = io('wss://grandhustler.com/socket.io');
    setSocket(newSocket);
    
    return () => {
      newSocket.close();
    };
  }, []);

  const joinUserRoom = (userId) => {
    if (socket) {
      socket.emit('join_user_room', userId);
      console.log(`Requested to join user room ${userId}`);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('new_invitation', (invitation) => {
        let notiArray = [];
        notiArray.push(invitation);
        const totalInvitations = notiArray.length;

        if(totalInvitations > 0){
          setNotifications(totalInvitations);
        }
      });

      socket.on('rejected', (data) => {
        console.log(`Invitation rejected`);
      });

      socket.on('game_accepted', (data) => {
        navigate(`/game/${data.roomId}`, { 
          state: { 
            roomId: data.roomId, 
            flip: data.flip,
            color: data.color, 
            time: data.time, 
            variation: data.variation, 
            player1Username: data.player1Username, 
            player2Username: data.player2Username,
            player1Id: data.player1Id,
            player2Id: data.player2Id,
          } 
        });
      });

      socket.on('join_game_tournament', (data) => {
        const startNextRoundMessage = document.querySelector('.start-next');
        if(startNextRoundMessage){
          startNextRoundMessage.textContent = `starting next round...`;
        }
        setTimeout(() => {
          navigate(`/lobby/${data.roomId}`);
      
          setTimeout(() => {
              navigate(`/game/${data.gameRoomId}`, { 
                  state: { 
                      roomId: data.roomId, 
                      gameRoomId: data.gameRoomId, 
                      flip: data.flip,  
                      time: data.time,
                      tournament: true,
                      currentRound: data.currentRound,
                      totalRounds: data.totalRounds,
                      initialStandings: data.standings,
                      variation: data.variation,
                  } 
              });
          }, 100); // Adjust the delay (in milliseconds) as needed
        }, 5000);
    });
      return () => {
        socket.off('new_invitation');
        socket.off('rejected');
        socket.off('game_accepted');
        socket.off('join_game_tournament');
      };
    }
  }, [socket]);

  return (
    <WebSocketContext.Provider value={{ socket, joinUserRoom, notifications }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};