import React, { useState } from 'react';
import EmailInput from './EmailInput';
import UsernameInput from './RegUsernameInput';
import PasswordInput from './PasswordInput';
import StateSelect from './RegStateSelect';
import DateOfBirthSelect from './RegDOBSelect';

const RegistrationForm = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobDay, setDobDay] = useState('');
  const [dobYear, setDobYear] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    username: '',
    password: '',
    state: '',
    dob: ''
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateUsername = (username) => {
    return username.length >= 3 && username.length <= 14;
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return passwordRegex.test(password);
  };

  const validateState = (state) => {
    return state !== '';
  };

  const validateDateOfBirth = (month, day, year) => {
    return month && day && year && day >= 1 && day <= 31 && year > 1900;
  };

  const calculateAge = (month, day, year) => {
    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) newErrors.email = 'Email is required';
    else if (!validateEmail(email)) newErrors.email = 'Invalid email format';

    if (!username) newErrors.username = 'Username is required';
    else if (!validateUsername(username)) newErrors.username = 'Username must be 3-14 characters long';

    if (!password) newErrors.password = 'Password is required';
    else if (!validatePassword(password)) newErrors.password = 'Password must be at least 8 characters long and include uppercase, lowercase, and a number';

    if (!selectedState) newErrors.state = 'State is required';

    if (!validateDateOfBirth(dobMonth, dobDay, dobYear)) {
      newErrors.dob = 'Date of birth is required and must be valid';
    } else if (calculateAge(dobMonth, dobDay, dobYear) < 18) {
      newErrors.dob = 'You must be at least 18 years old';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await fetch('https://grandhustler.com:3000/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
              mutation {
                register(
                  email: "${email}", 
                  username: "${username}", 
                  password: "${password}",
                  dobmonth: ${dobMonth},
                  dobday: ${dobDay},
                  dobyear: ${dobYear},
                ) {
                  id
                  email
                  username
                }
              }
            `
          }),
        });
    
        const result = await response.json();
        if (result.data) {
          console.log('Registration successful');
        } else {
          setErrors({ ...errors, general: `Registration failed: ${result.errors[0].message}` });
        }
      } catch (error) {
        console.error('Error during registration:', error);
        setErrors({ ...errors, general: 'An error occurred during registration' });
      }
    }
  };

  return (
    <div className="stack x-stretch y-center gap-large padding-none direction-vertical padding-left-auto padding-top-auto padding-bottom-auto padding-right-auto samri-17zurke">         
      <form
        data-test-form-valid="true"
        method="post"
        id="registrationForm"
        className="samri-3jiw3t"
        onSubmit={handleSubmit}
      >
        <EmailInput 
          email={email}
          setEmail={setEmail}
          error={errors.email}
        />
        <UsernameInput 
          username={username}
          setUsername={setUsername}
          error={errors.username}
        />
        <PasswordInput 
          password={password}
          setPassword={setPassword}
          error={errors.password}
        />
        <StateSelect 
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          error={errors.state}
        />
        <DateOfBirthSelect 
          dobMonth={dobMonth}
          dobDay={dobDay}
          dobYear={dobYear}
          setDobMonth={setDobMonth}
          setDobDay={setDobDay}
          setDobYear={setDobYear}
          error={errors.dob}
        />
        {errors.general && <div className="error">{errors.general}</div>}
        <button 
          data-test="button-register" 
          className="button variant-success size-xl align-center min-width samri-1epmied" 
          type="submit" 
          name="Create" 
          data-analytics="button-register"
        >
          <span className="content-or-loader samri-1uofbko">Continue</span>
        </button>
      </form>
    </div>
  );
};

export default RegistrationForm;