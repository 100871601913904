import React from 'react';
import '../css/buttons.css'
const HTHSection = ({ onWagerSelect }) => {
  // Handles button clicks
  const handleButtonClick = (event, wagerAmount, time) => {
    // Get all elements with the role="button" attribute
    const buttons = document.querySelectorAll('[role="button"]');
    
    // Remove 'chosen' class from all buttons
    buttons.forEach(button => button.classList.remove('chosen'));

    // Add 'chosen' class to the clicked button
    event.currentTarget.classList.add('chosen');

    onWagerSelect({ wagerAmount, time });
  };

  return (
    <section id="HTH" className="tab">
      <ul>
        <li>
          <div className="game-title">
            <div className="flex">
              <span>Classic</span>
              <span className="icon classic">
                <svg viewBox="0 0 24 24" width="18px" height="18px" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M12 7V12H15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="rgb(53,188,58)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </g>
                </svg>
              </span>
            </div>
            <a className='flex fg-0 ai-c gap-2' href="tournamentClassic.html">
              <span>View Tournaments</span>
              <svg width="5" height="9" viewBox="0 0 5 9" fill="rgb(172 255 195)" style={{ flexShrink: 0 }}>
                <g id="arrow-right_svg__Page-1" stroke="none" strokeWidth="1">
                  <path d="M.148.148a.5.5 0 000 .71l3.636 3.637L.148 8.143a.502.502 0 00.709.71l3.995-3.998a.5.5 0 000-.71L.857.149a.5.5 0 00-.71 0z" id="arrow-right_svg__arrow-right"></path>
                </g>
              </svg>
            </a>
          </div>
        </li>

        <li id="li">
          <div id="10MinHTH" className="gamebox">
            <div className="time-parent">
              <div id="time-container">
                <span role="text" className="gamebox-time">10 min</span>
              </div>
            </div>
            <div className="rectangle"></div>
            <div className="button-section">
              <div className="row-1">
                <div
                  id="schth10"
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 10000, '10 min')}
                >
                  <span className="wager-text">+10,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-1 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 50000, '10 min')}
                >
                  <span className="wager-text">+50,000</span>
                </div>
              </div>
              <div className="row-2">
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 100000, '10 min')}
                >
                  <span className="wager-text">+100,000</span>
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  className="button wager inputs-2 bck-reg c-reg"
                  onClick={(e) => handleButtonClick(e, 200000, '10 min')}
                >
                  <span className="wager-text">+200,000</span>
                </div>
              </div>
            </div>
            <div className="multiplier-payout">
              <div className="mp-child">
                <div className="multiplier"><span>2x</span></div>
                <div className="payout"><span>payout</span></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default HTHSection;