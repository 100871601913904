import React from 'react';
import HTHSection from './HTHSection';
import BulletSection from './BulletSection';
import BlitzSection from './HomeBlitzSection';

const GameList = ({ onWagerSelect }) => {
  return (
    <div className="list" style={{ background: '#242226', padding: '1rem', borderRadius: '0.5rem' }}>
      <ul className="tickets">
        <HTHSection onWagerSelect={onWagerSelect} />

        <div className="main-banner-wrapper">
          <div>
            <a href="https://www.kickstarter.com/projects/bryghtlabs/chessup-2?ref=92dnrh" target="_blank" rel="noopener noreferrer">
              <img alt="Marketing Banner" src="https://images.chesscomfiles.com/uploads/ad_image/18713.c0ab4e20.png" />
            </a>
          </div>
        </div>

        <BulletSection onWagerSelect={onWagerSelect}/>
        <BlitzSection onWagerSelect={onWagerSelect}/>
      </ul>
    </div>
  );
};

export default GameList;