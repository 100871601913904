import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../css/tournament.css';
import InsufficientFundsModal from './InsufficientFundsModal';

const ClassicGrid = () => {
  const [tournaments, setTournaments] = useState([]);
  const [freeCashTournaments, setFreeCashTournaments] = useState([]);
  const [joinedTournaments, setJoinedTournaments] = useState([]);
  const [authToken] = useState(localStorage.getItem('token')); 
  const [showInsufficientFundsModal, setShowInsufficientFundsModal] = useState(false);
  const [wagerToJoin, setWagerToJoin] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchTournaments() {
      try {
        if (!authToken) {
          throw new Error('No authentication token found');
        }
    
        const response = await fetch('http://localhost:5000/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            query: `
              query GetTournaments($gameMode: String!) {
                tournaments(gameMode: $gameMode) {
                  id
                  name
                  currentParticipants
                  maxParticipants
                  wager
                  time
                  timeMode
                  roomId
                  participants
                  minElo
                  maxElo
                }
              }
            `,
            variables: {
              gameMode: 'Classical',
            },
          }),
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch tournaments');
        }
    
        const result = await response.json();
        
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
    
        const tournamentsData = result.data.tournaments;
        
        setTournaments(tournamentsData);
    
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    }

    async function fetchFreeCashTournaments() {
      try {
        if (!authToken) {
          throw new Error('No authentication token found');
        }
    
        const response = await fetch('http://localhost:5000/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            query: `
              query GetFreeCashPrizeTournaments($gameMode: String!) {
                getFreeCashPrizeTournaments(gameMode: $gameMode) {
                  id
                  name
                  currentParticipants
                  maxParticipants
                  wager
                  time
                  timeMode
                  roomId
                  participants
                  cashPrizeAmount
                  minElo
                  maxElo
                }
              }
            `,
            variables: {
              gameMode: 'Classical', // or the appropriate game mode
            },
          }),
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch free cash tournaments');
        }
    
        const result = await response.json();
    
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
    
        const freeCashTournamentsData = result.data.getFreeCashPrizeTournaments;
        setFreeCashTournaments(freeCashTournamentsData);
    
      } catch (error) {
        console.error('Error fetching free cash tournaments:', error);
      }
    }

    async function fetchJoinedTournaments() {
      try {
        if (!authToken) {
          throw new Error('No authentication token found');
        }
    
        const response = await fetch('http://localhost:5000/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            query: `
              query JoinedTournaments {
                joinedTournaments {
                  id
                  name
                  currentParticipants
                  maxParticipants
                  wager
                  time
                  timeMode
                  roomId
                  participants
                  cashPrizeAmount 
                }
              }
            `,
          }),
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch tournaments');
        }
    
        const result = await response.json();
        
        if (result.errors) {
          throw new Error(result.errors[0].message);
        }
    
        const tournamentsData = result.data.joinedTournaments;
        setJoinedTournaments(tournamentsData);
    
      } catch (error) {
        console.error('Error fetching joined tournaments:', error);
      }
    }

    fetchTournaments();
    fetchFreeCashTournaments();
    fetchJoinedTournaments();
  }, [authToken]);

   const checkFunds = async (wager) => {
    try {
      const token = localStorage.getItem('token');
      const cleanedWager = cleanWagerInput(wager);

      const response = await fetch('http://localhost:5000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            query SufficientFunds($wager: Float!) {
              sufficientFunds(wager: $wager)
            }
          `,
          variables: { wager: cleanedWager }
        }),
        credentials: 'include'
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      return result.data.sufficientFunds;
    } catch (error) {
      console.error('Error checking funds:', error);
      return false;
    }
  };

  const cleanWagerInput = (input) => {
    // Convert input to string if it's not already a string
    const inputString = String(input);
    console.log(inputString);
    return parseFloat(inputString.replace(/,/g, '')) || 0;
  };

  async function putMoneyInPot(wager) {
    try {
        const token = localStorage.getItem('token');
        const cleanedWager = cleanWagerInput(wager);
  
        if (!token) {
            throw new Error('No token found in localStorage');
        }
    
        const response = await fetch('http://localhost:5000/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              query: `
                  mutation PutMoneyInPot($wager: Float!, $join: Boolean!) {
                      putMoneyInPot(wager: $wager, join: $join)
                  }
              `,
                variables: { wager: cleanedWager, join: true }
            })
        });
    
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
        }
    
        const data = await response.json();
        if (data.errors) {
            throw new Error(`GraphQL error: ${data.errors.map(err => err.message).join(', ')}`);
        }
        return data;
    } catch (error) {
        console.error('Error putting money in pot:', error);
    }
  }
  
  const joinTournament = async (tournamentId, wager) => {
    try {
      const hasSufficientFunds = await checkFunds(wager);
        if (hasSufficientFunds) {
          await putMoneyInPot(wager);
        } else {
          setWagerToJoin(wager);
          setShowInsufficientFundsModal(true); 
        }
     

      const response = await fetch('http://localhost:5000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          query: `
            mutation JoinTournament($tournamentId: ID!) {
              joinTournament(tournamentId: $tournamentId) {
                id
                currentParticipants
                participants
              }
            }
          `,
          variables: {
            tournamentId,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to join tournament');
      }

      const result = await response.json();

      const updatedTournament = result.data.joinTournament;
      setTournaments(prevTournaments =>
        prevTournaments.map(tournament =>
          tournament.id === updatedTournament.id ? updatedTournament : tournament
        )
      );

      window.location.reload();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
    } catch (error) {
      console.error('Error joining tournament:', error);
    }
  };

  const leaveTournament = async (tournamentId) => {
    console.log('here first', tournamentId);
    try {
      const response = await fetch('http://localhost:5000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          query: `
            mutation LeaveTournament($tournamentId: ID!) {
              leaveTournament(tournamentId: $tournamentId) {
                success
                message
              }
            }
          `,
          variables: {
            tournamentId,
          },
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to leave tournament');
      }
  
      const result = await response.json();
  
      if (result.errors) {
        throw new Error(result.errors[0].message);
      }
      console.log('results', result.data);
      const updatedTournament = result.data.leaveTournament;
      
      setJoinedTournaments(prevTournaments =>
        prevTournaments.filter(tournament => tournament.id !== tournamentId)
      );
  
      setTournaments(prevTournaments =>
        prevTournaments.map(tournament =>
          tournament.id === updatedTournament.id ? updatedTournament : tournament
        )
      );
  
      window.location.reload();
    } catch (error) {
      console.error('Error leaving tournament:', error);
    }
  };

  const handleCloseModal = () => {
    setShowInsufficientFundsModal(false);
  };


  return (
    <div className="page-layout">
      <div className="page-main">
        <section>
          <div className="groupsHome">
            <div className="flex">
              
              <div className="listCard-title">Top Classic Tournaments</div>
              <div className="listCard-title-sm">Create a tournament and automatically get a percentage of the pot!</div>
              <div className='email-push'>
                <svg
                  viewBox="0 -2.5 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  fill="#fffff"
                  stroke="#fffff"
                  width={ 18 }
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>Classic Tournament Emails</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -922.000000)" fill="#ffffffff">
                        <g id="icons" transform="translate(56.000000, 160.000000)">
                          <path
                            d="M262,764.291 L254,771.318 L246,764.281 L246,764 L262,764 L262,764.291 Z M246,775 L246,766.945 L254,773.98 L262,766.953 L262,775 L246,775 Z M244,777 L264,777 L264,762 L244,762 L244,777 Z"
                            id="email-[#ffffffff1573]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div className="responsive-table">
              <div className="flex">
                <div className="scroller">
                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                    <thead className="table-head">
                      <tr className="table-tr">
                        <th className="table-th">Group Name</th>
                        <th className="table-th grouplist-card">Size</th>
                        <th className="table-th grouplist-card">Wager</th>
                        <th className="table-th grouplist-card">Elo Range</th>
                        <th className="table-th grouplist-card">Time</th>
                        <th className="table-th grouplist-card">Starts At</th>
                        <th className="group-join table-th grouplist-card"></th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {tournaments.map(tournament => (
                        <tr key={tournament.id} className="table-tr">
                          <td className="table-td">
                            <div className="group">
                              <div className="headshot"></div>
                              <a href={`/ghsite/public/tournamentClassic.html?id=${tournament.roomId}`}>{tournament.name}</a>
                            </div>
                          </td>
                          <td className="group-size table-td grouplist-card">
                          {tournament.currentParticipants}/{tournament.maxParticipants === 999999 ? '∞' : tournament.maxParticipants}
                          </td>
                          <td className="group-size table-td grouplist-card">{tournament.wager}</td>
                          <td className="group-size table-td grouplist-card">{tournament.minElo + ' - ' + tournament.maxElo}</td>
                          <td className="group-size table-td grouplist-card">{tournament.timeMode}</td>
                          <td className="group-size table-td grouplist-card">{tournament.time}</td>
                          <td className="group-join table-td grouplist-card">
                            <button className="join-tournament" onClick={() => joinTournament(tournament.id, tournament.wager)}>
                              <span>Join</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="page-layout">
        <div className="page-main">
          <section>
            <div className="groupsHome">
              <div className="flex">
                <div className="listCard-title nowrap">Free Cash Classic Tournaments</div>
                <div className="listCard-title-sm">Join a tournament for free and have a chance to win real money!</div>
                <div className='email-push'>
                  <svg
                    viewBox="0 -2.5 20 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#fffff"
                    stroke="#fffff"
                    width={ 18 }
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>Free Cash Tournament Emails</title>
                      <desc>Created with Sketch.</desc>
                      <defs></defs>
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -922.000000)" fill="#ffffffff">
                          <g id="icons" transform="translate(56.000000, 160.000000)">
                            <path
                              d="M262,764.291 L254,771.318 L246,764.281 L246,764 L262,764 L262,764.291 Z M246,775 L246,766.945 L254,773.98 L262,766.953 L262,775 L246,775 Z M244,777 L264,777 L264,762 L244,762 L244,777 Z"
                              id="email-[#ffffffff1573]"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div className="responsive-table">
                <div className="flex">
                  <div className="scroller">
                    <table style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                      <thead className="table-head">
                        <tr className="table-tr">
                          <th className="table-th">Group Name</th>
                          <th className="table-th grouplist-card">Size</th>
                          <th className="table-th grouplist-card">Prize</th>
                          <th className="table-th grouplist-card">Elo Range</th>
                          <th className="table-th grouplist-card">Time</th>
                          <th className="table-th grouplist-card">Starts At</th>
                          <th className="group-join table-th grouplist-card"></th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {freeCashTournaments.map(tournament => (
                          <tr key={tournament.id} className="table-tr">
                            <td className="table-td">
                              <div className="group">
                                <div className="headshot"></div>
                                <a href={`/ghsite/public/tournamentClassic.html?id=${tournament.roomId}`}>{tournament.name}</a>
                              </div>
                            </td>
                            <td className="group-size table-td grouplist-card">
                            {tournament.currentParticipants}/{tournament.maxParticipants === 999999 ? '∞' : tournament.maxParticipants}
                            </td>
                            <td className="group-size table-td grouplist-card">{(tournament.cashPrizeAmount * 10000).toLocaleString()}</td>
                            <td className="group-size table-td grouplist-card">{tournament.minElo + ' - ' + tournament.maxElo}</td>
                            <td className="group-size table-td grouplist-card">{tournament.timeMode}</td>
                            <td className="group-size table-td grouplist-card">{tournament.time}</td>
                            <td className="group-join table-td grouplist-card">
                              <button className="join-tournament" onClick={() => joinTournament(tournament.id, tournament.wager)}>
                                <span>Join</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="joined page-layout">
        <div className="page-main">
          <section>
            <div className="groupsHome">
              <div className="flex">
                <div className="listCard-title">Joined Tournaments</div>
                <div className="listCard-title-sm">You Will Be Able To Join 5 Min From Start</div>
              </div>
              <div className="responsive-table">
                <div className="flex">
                  <div className="scroller">
                    <table style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                      <thead className="table-head">
                        <tr className="table-tr">
                          <th className="group-join table-th grouplist-card"></th>
                          <th className="table-th">Group Name</th>
                          <th className="table-th grouplist-card">Size</th>
                          <th className="table-th grouplist-card">Pot. Payout</th>
                          <th className="table-th grouplist-card">Time</th>
                          <th className="table-th grouplist-card">Starts At</th>
                          <th className="group-join table-th grouplist-card"></th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {joinedTournaments.map(tournament => (
                          <tr key={tournament.id} className="table-tr">
                            <td className="group-leave table-td grouplist-card">
                              <button
                                className="leave-tournament"
                                style={{
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  padding: '5px',
                                  cursor: 'pointer',
                                  borderRadius: '4px',
                                }}
                                onClick={() => leaveTournament(tournament.roomId)}
                              >
                                <svg
                                  viewBox="0 0 25 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ width: '20px', height: '20px' }}
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <path
                                      d="M16.5 15V19.5H5.5V5.5H16.5V10M10 12.5H22.5"
                                      stroke="#ed4163"
                                      strokeWidth="1.2"
                                    ></path>
                                    <path
                                      d="M20 10L22.5 12.5L20 15"
                                      stroke="#ed4163"
                                      strokeWidth="1.2"
                                    ></path>
                                  </g>
                                </svg>
                              </button>
                            </td>
                            <td className="table-td">
                              <div className="group">
                                <div className="headshot"></div>
                                <a href={`/ghsite/public/tournamentClassic.html?id=${tournament.roomId}`}>{tournament.name}</a>
                              </div>
                            </td>
                            <td className="group-size table-td grouplist-card">
                            {tournament.currentParticipants}/{tournament.maxParticipants === 999999 ? '∞' : tournament.maxParticipants}
                            </td>
                            <td className="group-size table-td grouplist-card">{tournament.cashPrizeAmount ? (tournament.cashPrizeAmount * 10000).toLocaleString() : ((tournament.wager * 10000) * tournament.currentParticipants).toLocaleString()}</td>
                            <td className="group-size table-td grouplist-card">{tournament.timeMode}</td>
                            <td className="group-size table-td grouplist-card">{tournament.time}</td>
                            <td className="group-join table-td grouplist-card">
                              <button className="join-tournament" onClick={() => navigate(`/lobby/${tournament.roomId}`)}>
                                <span>Lobby</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {showInsufficientFundsModal && (
        <InsufficientFundsModal
          closeModal={handleCloseModal}
          wager={wagerToJoin} 
        />
      )}
    </div>
  );
};

export default ClassicGrid;