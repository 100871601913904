import React, { useEffect, useState } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import FullBetSlip from './FullBetslip';
import '../css/betslip.css'

const BetSlip = ({ selectedWager }) => {
  const { socket, notifications } = useWebSocket();
  const [invitationList, setInvitationList] = useState([]);
  const [isFullBetSlipOpen, setIsFullBetSlipOpen] = useState(false);

  const handleNewInvitation = (invitation) => {
    setInvitationList((prevList) => {
      const invitationIndex = prevList.findIndex(
        (inv) => inv.invitationId === invitation.invitationId
      );
  
      document.querySelector('#num-container span').textContent = 1;

      if (invitationIndex > -1) {
        const updatedList = [...prevList];
        updatedList[invitationIndex] = invitation;
        return updatedList;
      } else {
        return [invitation]; 
      }
    });
  };

  useEffect(() => {
    if (socket) {
      socket.on('send_invitation', handleNewInvitation);
      socket.on('new_invitation', handleNewInvitation);
      
      return () => {
        socket.off('send_invitation', handleNewInvitation);
        socket.off('new_invitation', handleNewInvitation);
      };
    }
  }, [socket]);

  const handleDeleteInvitation = async (invitationId) => {
    try {
      setInvitationList((prevList) =>
        prevList.filter((invitation) => invitation.invitationId !== invitationId)
      );

      document.querySelector('#num-container span').textContent = 0;

      const token = localStorage.getItem('token');
      const response = await fetch('http://localhost:5000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            mutation RejectInvitation($invitationId: ID!) {
              rejectInvitation(invitationId: $invitationId) {
                success
                message
              }
            }
          `,
          variables: { invitationId }
        })
      });

      const result = await response.json();
  
      if (!result.data.rejectInvitation.success) {
        console.error('Error rejecting invitation:', result.data.rejectInvitation.message);
      }
    } catch (error) {
      console.error('Error rejecting invitation:', error);
    }
  };

  
  const handleAcceptInvitation = async (invitationId, wager) => {
    try {
      // Ensure wager is a float
      const wagerFloat = parseFloat(wager);
      
      console.log('wager', wagerFloat);
  
      // Check if the user has sufficient funds
      const hasSufficientFunds = await checkFunds(wagerFloat);
      if (hasSufficientFunds) {
        putMoneyInPot(wagerFloat);
  
        const token = localStorage.getItem('token');
        const response = await fetch('http://localhost:5000/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            query: `
              mutation AcceptInvitation($invitationId: ID!) {
                acceptInvitation(invitationId: $invitationId) {
                  success
                  message
                  roomId
                  color
                }
              }
            `,
            variables: { invitationId }
          })
        });
  
        const result = await response.json();
        if (!result.data.acceptInvitation.success) {
          console.error('Error accepting invitation:', result.data.acceptInvitation.message);
        }
      } else {
        console.log('insufficient funds');
      }
    } catch (error) {
      console.error('Error accepting invitation:', error);
    }
  };

  async function checkFunds(cleanedWager) {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('http://localhost:5000/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: `
            query SufficientFunds($wager: Float!) {
              sufficientFunds(wager: $wager)
            }
          `,
          variables: { wager: cleanedWager }
        }),
        credentials: 'include'
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      return result.data.sufficientFunds;
    } catch (error) {
      console.error('Error checking funds:', error);
      return false;
    }
  }

  async function putMoneyInPot(cleanedWager) {
    try {
        const token = localStorage.getItem('token');
        const freeCashPrize = false;
        
        if (!token) {
            throw new Error('No token found in localStorage');
        }
    
        const response = await fetch('http://localhost:5000/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                query: `
                    mutation PutMoneyInPot($wager: Float!, $join: Boolean!) {
                        putMoneyInPot(wager: $wager, join: $join)
                    }
                `,
                variables: { 
                    wager: cleanedWager,
                    join: freeCashPrize ? true : false,
                }
            })
        });
    
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorText}`);
        }
    
        const data = await response.json();
        if (data.errors) {
            throw new Error(`GraphQL error: ${data.errors.map(err => err.message).join(', ')}`);
        }

        return data;
    } catch (error) {
        console.error('Error putting money in pot:', error);
    }
  }


  const isContainerEmpty = document.querySelector('.bs-empty-title') !== null;
  const isThereASelectedElement = document.querySelector('.chessbook-content-inner-wrapper.full');

  const handleCloseBetSlip = () => {
    setIsFullBetSlipOpen(false);
    if(document.querySelector('.button.chosen')){
      document.querySelector('.button.chosen').classList.remove('chosen');
    }
  };

  // Function to open FullBetSlip when a new wager is selected
  const handleOpenBetSlip = () => {
    setIsFullBetSlipOpen(true); // Open the full bet slip
  };

  // Open bet slip if there's a selected wager
  useEffect(() => {
    if (selectedWager) {
      handleOpenBetSlip();
    }
  }, [selectedWager]);

  return (
    <div className="chessbook-wrapper" style={{ right: '45px' }}>
      <div className="chessbook-betslip is-empty is-fixed">
        <div className="betslip-empty-container">
          <div className="betslip-shell-container">
            <div className="betslip-shell-wrapper">
              <div className="betslip-shell-accordion">
                <div id="bstpc">
                  <div id="bstcc">
                    <div id="bst">
                      <div id="bst-center">
                        <div id="bst-line">
                          <div id="num-bs">
                            <div id="num-container-margin">
                              <div id="num-container">
                                <span id="num">{notifications}</span>
                              </div>
                            </div>
                            <div id="betslip">BET SLIP</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chessbook-betslip-accordion-children-wrapper" style={{ overflow: 'visible' }}>
                  <div className="betslip-accordian-wrapper-inner">
                    <div className="gh-wrapper">
                    {isFullBetSlipOpen && selectedWager && (isContainerEmpty || isThereASelectedElement) ? (
                        <FullBetSlip 
                          selectedWager={selectedWager} 
                          containerEmpty={isContainerEmpty} 
                          isThereASelectedElement={isThereASelectedElement}
                          onClose={handleCloseBetSlip}
                        />
                      ) : invitationList.length === 0 ? (
                        <div className="bs-empty-title">
                          <div className="bs-empty">Bet slip empty</div>
                          <div className="bs-empty-subtitle">Add selection and your pick will show here.</div>
                        </div>
                      ) : (
                        invitationList.map((invitation, index) => (
                          <div key={index} className="single-card">
                            <div className="card-animation-wrapper">
                              <div style={{ overflow: 'hidden', height: 'auto', transition: 'height 0.3s ease-in 0s' }}>
                                <div style={{ transform: 'translateY(0%)' }}>
                                  <div className="hth-betslip-card-container">
                                    <div className="hth-betslip-card-wrapper">
                                      <div className="hth-betslip-xbutton-container">
                                        <div className="xbutton-div" onClick={() => handleDeleteInvitation(invitation.invitationId)}>
                                          <svg
                                            role="img"
                                            aria-label="Close"
                                            className="sportsbook__icon--ex"
                                            fill="#ababab"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 32 32"
                                          >
                                            <title>Icon representing an X shape</title>
                                            <path d="M18.848 16.384l10.848-10.848c0.352-0.352 0.352-0.96 0-1.312l-1.44-1.44c-0.352-0.352-0.96-0.352-1.312 0l-10.88 10.848h-0.032l-10.816-10.848c-0.352-0.352-0.96-0.352-1.312 0l-1.44 1.44c-0.352 0.352-0.352 0.96 0 1.312l10.848 10.848v0.032l-10.624 10.656c-0.352 0.352-0.352 0.96 0 1.312l1.44 1.44c0.352 0.352 0.96 0.352 1.312 0l10.656-10.656h0.032l10.656 10.656c0.352 0.352 0.96 0.352 1.312 0l1.44-1.44c0.352-0.352 0.352-0.96 0-1.312l-10.688-10.688c-0.032 0.032-0.032 0.032 0 0z"></path>
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="single-card-combination">
                                        <div className="single-card-header">
                                          <div className="single-card-game">
                                            <span className="single-card-game-text">{invitation.senderUsername}</span>
                                          </div>
                                          <div className="single-card-comp"></div>
                                          <div className="single-card-time">{invitation.time}</div>
                                        </div>
                                        <div className="single-card-wager-amount">
                                          <span className="single-card-wager-amount-text">{parseFloat(invitation.wager).toLocaleString()}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="response-buttons">
                                    <button className="reject" onClick={() => handleDeleteInvitation(invitation.invitationId)}>
                                      <svg viewBox="0 0 24 24" width="20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"></path> </g></svg>
                                    </button>
                                    <button className="accept" onClick={() => handleAcceptInvitation(invitation.invitationId, invitation.wager)}>
                                      <div className="chessboard"></div>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetSlip;