import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import io from 'socket.io-client';

// Define your GraphQL mutation for storing the message
const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage($recipientId: ID!, $message: String!) {
    sendMessage(recipientId: $recipientId, message: $message) {
      id
      message
      senderId
      timestamp
    }
  }
`;

const GET_MESSAGES_QUERY = gql`
  query GetMessages($roomId: ID!, $userId: ID!) {
    messages(roomId: $roomId, userId: $userId) {
      id
      message
      senderId
      receiverId
      timestamp
    }
  }
`;

const socket = io('https://grandhustler.com:3000'); // Update the URL to your WebSocket server

const ChatRoom = ({ roomId, user }) => {
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [messageHeights, setMessageHeights] = useState({});

  const messageInputRef = useRef(null);
  const timelineRef = useRef(null);

  const { loading, error, data } = useQuery(GET_MESSAGES_QUERY, {
    variables: { roomId, userId: user.id },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const [sendMessage] = useMutation(SEND_MESSAGE_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  useEffect(() => {
    if (data && data.messages) {
      setMessages(data.messages);
    }
  }, [data]);

  useEffect(() => {
    const handleInput = () => {
      if (messageInputRef.current) {
        const isMessageNotEmpty = messageInputRef.current.textContent.trim().length > 0;
        setShowPlaceholder(!isMessageNotEmpty);
      }
    };

    const currentMessageInput = messageInputRef.current;
    if (currentMessageInput) {
      currentMessageInput.addEventListener('input', handleInput);
    }

    return () => {
      if (currentMessageInput) {
        currentMessageInput.removeEventListener('input', handleInput);
      }
    };
  }, []);

  useEffect(() => {
    if (timelineRef.current) {
      const messageCount = messages.length;
      const heightPerMessage = 90.4; // Adjust if necessary
      const calculatedMinHeight = (heightPerMessage * messageCount + 27.2) + 272.8; // Extra padding if needed
      timelineRef.current.style.minHeight = `380.8px`;
    }
  }, [messages]);

  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    socket.on('receive_message', (newMessage) => {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, newMessage];
        setMessageHeights(prevHeights => ({
          ...prevHeights,
          [newMessage.id]: ((90.4 * newMessages.length) + 272.8) // Incrementally position each new message
        }));
        return newMessages;
      });
    });

    return () => {
      socket.off('receive_message');
    };
  }, []);

  const handleMessageChange = (event) => {
    setMessage(event.target.innerText);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const messageContent = messageInputRef.current.textContent.trim();
    if (!messageContent) return;

    // Send message to GraphQL server
    try {
      const { data } = await sendMessage({
        variables: {
          recipientId: user.id,
          message: messageContent,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      socket.emit('send_message', data.sendMessage);
      
      // Update local message list
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, data.sendMessage];
        setMessageHeights(prevHeights => ({
          ...prevHeights,
          [data.sendMessage.id]: ((90.4 * newMessages.length) + 272.8) // Incrementally position each new message
        }));
        return newMessages;
      });

      // Clear the input
      messageInputRef.current.textContent = '';
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (loading) return <p>Loading messages...</p>;
  if (error) return <p>Error loading messages: {error.message}</p>;

  return (
    <section className={`room ${roomId}`}>
      <header className="p-m gap-s w-full ai-c flex border-box h-275">
        <div className="pl-m mr-auto mw-100-40">
          <div className="semibold text-1 font-sans o-h c-w">
            {user.username}
          </div>
        </div>
      </header>

      <div className="timeline p-1 scrollbar" ref={timelineRef}>
        {messages.map((msg) => (
          <div
            key={msg.id}
            style={{
              position: 'absolute',
              top: `${messageHeights[msg.id] || 0}px`,
              width: '100%',
              paddingRight: '1.5rem',
            }}
            className="pb-rem24 standard fd-c"
          >
            <div role="button" tabIndex="0" className="ai-fe standard os-n pointer">
              <div className="w-full standard jc-fe fd-r">
                <div className="pe-ni pr-rem4 op-0 as-c fd-r jc-fe standard">
                  <div className="standard">
                    <div className="standard">
                      <div className="react-container standard pointer">
                        <div className="react">
                          <svg viewBox="0 0 24 24" aria-hidden="true" className="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-z80fyv r-19wmn03 r-qazpri">
                            <g>
                              <path d="M17 12v3h-2.998v2h3v3h2v-3h3v-2h-3.001v-3H17zm-5 6.839c-3.871-2.34-6.053-4.639-7.127-6.609-1.112-2.04-1.031-3.7-.479-4.82.561-1.13 1.667-1.84 2.91-1.91 1.222-.06 2.68.51 3.892 2.16l.806 1.09.805-1.09c1.211-1.65 2.668-2.22 3.89-2.16 1.242.07 2.347.78 2.908 1.91.334.677.49 1.554.321 2.59h2.011c.153-1.283-.039-2.469-.539-3.48-.887-1.79-2.647-2.91-4.601-3.01-1.65-.09-3.367.56-4.796 2.01-1.43-1.45-3.147-2.1-4.798-2.01-1.954.1-3.714 1.22-4.601 3.01-.896 1.81-.846 4.17.514 6.67 1.353 2.48 4.003 5.12 8.382 7.67l.502.299v-2.32z"></path>
                            </g>
                          </svg>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ai-fe fs-1 standard">
                  <div role="presentation" className="message-container standard">
                    <div dir="auto" style={{ textOverflow: 'unset', color: 'rgb(255, 255, 255)' }} className="message-text break-word c-w">
                      {msg.message}
                    </div>
                  </div>
                </div>
                <div className="pointer pt-3 standard">
                  <div className="pointer events all w-auto standard">
                    <time className="t-s time-standard pointer">
                      <span className="font-sans medium text-sm white-space-nowrap c-d">Mar 9</span>
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <form className="message-form" onSubmit={handleSubmit}>
        <div role="progressbar" aria-valuemax="100" aria-valuenow="0" className="zi-1 h-rem-02 us-n w-full o-h">
          <div className="progress z--1 h-full" style={{ width: '0%', backgroundColor: 'rgb(29, 155, 240)' }}></div>
        </div>
        <div className="message-instruments fd-r ai-c">
        <div className="image-buttons">
            <div className="img" role="button" tabIndex="0" style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
              <div className="img-child" style={{ textOverflow: 'unset', color: 'rgb(29, 155, 240)' }}>
                <svg className="h-rem20 fill mw-full relative w-rem20 d-ib va-tb" viewBox="0 0 24 24" aria-hidden="true">
                  <g>
                    <path d="M3 5.5C3 4.119 4.119 3 5.5 3h13C19.881 3 21 4.119 21 5.5v13c0 1.381-1.119 2.5-2.5 2.5h-13C4.119 21 3 19.881 3 18.5v-13zM5.5 5c-.276 0-.5.224-.5.5v9.086l3-3 3 3 5-5 3 3V5.5c0-.276-.224-.5-.5-.5h-13zM19 15.414l-3-3-5 5-3-3-3 3V18.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-3.086zM9.75 7C8.784 7 8 7.784 8 8.75s.784 1.75 1.75 1.75 1.75-.784 1.75-1.75S10.716 7 9.75 7z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <input accept="image/jpeg,image/png,image/webp,image/gif,video/mp4,video/quicktime" tabIndex="-1" type="file" className="file-input" />
            <div className="img" role="button" tabIndex="0" style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
              <div className="img-child" style={{ textOverflow: 'unset', color: 'rgb(29, 155, 240)' }}>
                <svg className="h-rem20 fill mw-full relative w-rem20 d-ib va-tb" viewBox="0 0 24 24" aria-hidden="true">
                  <g>
                    <path d="M3 5.5C3 4.119 4.12 3 5.5 3h13C19.88 3 21 4.119 21 5.5v13c0 1.381-1.12 2.5-2.5 2.5h-13C4.12 21 3 19.881 3 18.5v-13zM5.5 5c-.28 0-.5.224-.5.5v13c0 .276.22.5.5.5h13c.28 0 .5-.224.5-.5v-13c0-.276-.22-.5-.5-.5h-13zM18 10.711V9.25h-3.74v5.5h1.44v-1.719h1.7V11.57h-1.7v-.859H18zM11.79 9.25h1.44v5.5h-1.44v-5.5zm-3.07 1.375c.34 0 .77.172 1.02.43l1.03-.86c-.51-.601-1.28-.945-2.05-.945C7.19 9.25 6 10.453 6 12s1.19 2.75 2.72 2.75c.85 0 1.54-.344 2.05-.945v-2.149H8.38v1.032H9.4v.515c-.17.086-.42.172-.68.172-.76 0-1.36-.602-1.36-1.375 0-.688.6-1.375 1.36-1.375z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <div className="img" role="button" tabIndex="0" style={{ borderColor: 'transparent', backgroundColor: 'transparent' }}>
                <div className="img-child" style={{ textOverflow: 'unset', color: 'rgb(29, 155, 240)' }}>
                  <svg className="h-rem20 fill mw-full relative w-rem20 d-ib va-tb" viewBox="0 0 24 24" aria-hidden="true">
                    <g>
                      <path d="M8 9.5C8 8.119 8.672 7 9.5 7S11 8.119 11 9.5 10.328 12 9.5 12 8 10.881 8 9.5zm6.5 2.5c.828 0 1.5-1.119 1.5-2.5S15.328 7 14.5 7 13 8.119 13 9.5s.672 2.5 1.5 2.5zM12 16c-2.224 0-3.021-2.227-3.051-2.316l-1.897.633c.05.15 1.271 3.684 4.949 3.684s4.898-3.533 4.949-3.684l-1.896-.638c-.033.095-.83 2.322-3.053 2.322zm10.25-4.001c0 5.652-4.598 10.25-10.25 10.25S1.75 17.652 1.75 12 6.348 1.75 12 1.75 22.25 6.348 22.25 12zm-2 0c0-4.549-3.701-8.25-8.25-8.25S3.75 7.451 3.75 12s3.701 8.25 8.25 8.25 8.25-3.701 8.25-8.25z"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="text-area">
            <div>
              {showPlaceholder && <div className="text-placeholder">Start a new message</div>}
              <div 
                className="textbox" 
                role="textbox" 
                spellCheck="true" 
                autoCorrect="off" 
                contentEditable="true" 
                style={{ position: 'relative', outline: 'none', overflowWrap: 'break-word' }}
                onInput={handleMessageChange}
                ref={messageInputRef}
              >
                <div>
                  <span>
                    <span className="empty-text">
                      <span>&nbsp;</span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="textbox-bg"></div>
            </div>
            <div className="text-length">
              <div className="text-length-text"></div>
            </div>
          </div>
          <button 
            type="submit" 
            className="button send-button standard ai-c jc-c" 
            role="button"
            style={{ display: 'flex', width: '3rem', height: '2rem', borderRadius: '0.25rem', color: 'rgb(0, 149, 246)' }}
          >
            <svg className="h-rem20 fill mw-full relative w-rem20 d-ib va-tb" viewBox="0 0 24 24" aria-hidden="true">
              <g>
                <path d="M22.224 11.574l-17.356 7.989a.75.75 0 0 1-.984-.986l3.428-8.212-7.874-2.235a.75.75 0 0 1-.514-1.037l1.95-4.397A.75.75 0 0 1 1.847 2.7l19.332 6.9a.75.75 0 0 1 .513 1.037l-3.79 9.345a.75.75 0 0 1-1.037.514z"></path>
              </g>
            </svg>
          </button>
        </div>
      </form>
    </section>
  );
};

export default ChatRoom;